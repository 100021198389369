// - Import dependencies
import React from "react";

import { FiInstagram, FiLinkedin, FiGithub } from "react-icons/fi";
import { FaXTwitter } from "react-icons/fa6";

// Import Data
import useStaticData from "../../../hooks/useStaticData";

// - Import Styles
import "./index.scss";

const iconMap = {
  FaXTwitter: FaXTwitter,
  FiInstagram: FiInstagram,
  FiLinkedin: FiLinkedin,
  FiGithub: FiGithub,
};

const Footer = () => {
  const year = new Date().getFullYear();
  const data = useStaticData();

  if (!data) {
    return <span className="footer loading"></span>;
  }

  return (
    <div className="footer flex-center">
      &copy; {year} Degrande Yani | All Rights Reserved |{" "}
      <div className="flex-center">
        {data.social.map((item, index) => {
          const IconComponent = iconMap[item.icon]; // Get the icon component by its name
          return (
            <a key={index} href={item.url} target="_blank" rel="noreferrer">
              {IconComponent ? <IconComponent /> : null}{" "}
              {/* Render the icon if it exists */}
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default Footer;
