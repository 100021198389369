import React, { useState, useEffect } from "react";

import "./index.scss";

const SwitchFilter = ({
  options,
  defaultOption,
  onOptionChange,
  className,
}) => {
  const [selectedOption, setSelectedOption] = useState(defaultOption);

  // Synchronize internal state with prop changes
  useEffect(() => {
    setSelectedOption(defaultOption);
  }, [defaultOption]);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    onOptionChange(option);
  };

  return (
    <div className={`switches-container ${className}`}>
      {options.map((option, index) => (
        <button
          key={index}
          className={`switch ${selectedOption === option ? "active" : ""}`}
          onClick={() => handleOptionChange(option)}
        >
          <span>{option}</span>
        </button>
      ))}
    </div>
  );
};

export default SwitchFilter;
