import { Routes, Route } from "react-router-dom";

import Home from "./pages/home";
import View from "./pages/view";
import About from "./pages/about";
import Work from "./pages/work";
import Contactform from "./pages/contact";

function App() {
  return (
    <Routes>
      <Route element={<View />}>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/work" element={<Work />} />
        <Route path="/contact" element={<Contactform />} />
        <Route path="*" element={<Home />} />
      </Route>
    </Routes>
  );
}

export default App;
