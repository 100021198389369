import { Outlet } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";

import AnimatedCursor from "react-animated-cursor";

import Navbar from "../../components/navigation/navbar";
import Footer from "../../components/navigation/footer";
import ScrollToTopButton from "../../components/buttons/scrollToTop";

const View = () => {
  const [showScrollButton, setShowScrollButton] = useState(false);

  const mainRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const currentRef = mainRef.current;
      if (currentRef) {
        setShowScrollButton(currentRef.scrollTop > 300);
      }
    };

    if (mainRef.current) {
      const ref = mainRef.current;
      ref.addEventListener("scroll", handleScroll);

      return () => {
        ref.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  return (
    <div className="wrapper">
      {window.innerWidth > 768 ? (
        <AnimatedCursor
          innerSize={20}
          outerSize={30}
          outerAlpha={0.2}
          innerScale={0.7}
          outerScale={5}
          innerStyle={{
            backgroundColor: "var(--global-picker-color)", // Use RGBA
          }}
          outerStyle={{
            backgroundColor: "var(--global-picker-color-rgba)",
            opacity: 0.2,
          }}
          clickables={[
            "a",
            'input[type="text"]',
            'input[type="email"]',
            'input[type="number"]',
            'input[type="submit"]',
            'input[type="image"]',
            'input[type="checkbox"]',
            'input[type="color"]',
            "label[for]",
            "select",
            ".service",
            "textarea",
            "button",
            ".link",
            ".icon-container",
          ]}
          className="custom-cursor"
        />
      ) : null}

      <header className="wrapper-header flex-between">
        <Navbar />
      </header>
      <main className="wrapper-main" ref={mainRef}>
        <Outlet />
        {showScrollButton && <ScrollToTopButton mainRef={mainRef} />}
      </main>
      <footer className="wrapper-footer flex-center">
        <Footer />
      </footer>
    </div>
  );
};

export default View;
