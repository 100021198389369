import React, { useState, useEffect } from "react";
import Project from "../../components/cards/projectCard";
import SwitchFilter from "../../components/buttons/chips/projectFilterChips";

import ProjectDetailSidePanel from "../../components/sidepanels/projectDetailSidepanel";
import FilterSidePanel from "../../components/sidepanels/filterSidepanel";

// - Import Data
import useDocumentTitle from "../../hooks/useDocumentTitle";
import useStaticData from "../../hooks/useStaticData";

import { Helmet } from "react-helmet-async";

import AOS from "aos";
import "aos/dist/aos.css";

import "./index.scss";

const filterConfig = {
  category: {
    type: "switch",
    options: ["All", "Personal", "Work", "School"],
    default: "All",
  },
  technologies: {
    type: "checkbox",
    options: [
      "React",
      "JavaScript",
      "CSS",
      "Python",
      "Java",
      "SCSS",
      "Angular",
    ],
  },
  date: {
    type: "radio",
    options: ["last6months", "lastyear"],
  },
};

const Work = () => {
  useDocumentTitle("Yani Degrande - Work");
  const data = useStaticData();


  const pageData = data?.seo.work;
  const schemaData = pageData?.schema;

  const [filteredProjects, setFilteredProjects] = useState([]);
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  const [isProjectDetailPanelOpen, setIsProjectDetailPanelOpen] =
    useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [filters, setFilters] = useState({
    category: filterConfig.category.default,
    technologies: [],
    date: null,
  });

  useEffect(() => {
    if (data && data.work) {
      setFilteredProjects(data.work);
    } else {
      setFilteredProjects([]); // Fallback in case data is not available
    }
  }, [data]);

  const handleFilterChange = (filterName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));

    applyFilters({ ...filters, [filterName]: value });
  };

  const clearFilters = () => {
    const clearedFilters = {
      category: "All",
      technologies: [],
      date: null,
    };
    setFilters(clearedFilters);
    setFilteredProjects(data ? data.work : []);
  };

  const applyFilters = (activeFilters) => {
    let filteredResults = data ? data.work : [];

    if (activeFilters.category !== "All") {
      filteredResults = filteredResults.filter(
        (project) => project.category === activeFilters.category
      );
    }

    if (activeFilters.technologies.length > 0) {
      filteredResults = filteredResults.filter((project) =>
        activeFilters.technologies.every((tech) =>
          project.technologies.includes(tech)
        )
      );
    }

    if (activeFilters.date) {
      const dateLimit = new Date();
      if (activeFilters.date === "last6months") {
        dateLimit.setMonth(dateLimit.getMonth() - 6);
      } else if (activeFilters.date === "lastyear") {
        dateLimit.setFullYear(dateLimit.getFullYear() - 1);
      }

      filteredResults = filteredResults.filter(
        (project) => new Date(project.date) >= dateLimit
      );
    }

    setFilteredProjects(filteredResults);
  };

  const openProjectDetailPanel = (project) => {
    setSelectedProject(project);
    setIsProjectDetailPanelOpen(true);
  };

  const closeProjectDetailPanel = () => {
    setIsProjectDetailPanelOpen(false);
    setSelectedProject(null);
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  if (!data) {
    return (
      <div className="work__loader flex-column">
        <div className="work__loader__header">
          <span className="loading"></span>
          <span className="loading"></span>
          <span className="loading"></span>
          <span className="loading"></span>
        </div>
        <div className="work__loader__switch">
          <span className="loading"></span>
          <span className="loading"></span>
          <span className="loading"></span>
          <span className="loading"></span>
        </div>
        <div className="work__loader__content">
          <span className="loading"></span>
          <span className="loading"></span>
        </div>
      </div>
    );
  }

  return (
    <div className="work grid">
      <Helmet>
        <title>{pageData.title}</title>
        <meta name="description" content={pageData.description} />
        <meta name="keywords" content={pageData.keywords} />
        <meta property="og:title" content={pageData.ogTitle} />
        <meta property="og:description" content={pageData.ogDescription} />
        <meta property="og:image" content={pageData.ogImage} />
        <meta property="og:url" content={pageData.ogUrl} />
        <meta name="twitter:title" content={pageData.twitterTitle} />
        <meta
          name="twitter:description"
          content={pageData.twitterDescription}
        />
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
      </Helmet>
      <div className="work__header">
        <h2 data-aos="fade-down">Projects.</h2>
        <p data-oas="fade-right">
          Explore my portfolio of projects showcasing a diverse range of
          software development endeavors. From web applications to software
          solutions, each project represents a unique challenge met with
          innovative solutions. Dive in and discover the passion and dedication
          poured into each creation, reflecting my commitment to delivering
          high-quality solutions for my clients.
        </p>
      </div>
      <div className="work__switch" data-aos="fade-left">
        <SwitchFilter
          options={filterConfig.category.options}
          defaultOption={filters.category}
          onOptionChange={(value) => handleFilterChange("category", value)}
          className="my-switch-filter"
        />
        <div
          className="work__switch__filter"
          onClick={() => setIsFilterPanelOpen(true)}
        >
          <svg viewBox="0 0 22 22">
            <path d="M18.3423 1.40339C13.3879 0.865501 8.63302 0.865508 3.6785 1.40359C1.64305 1.62465 0.361097 3.78189 1.32915 5.65633C2.88798 8.67468 5.11052 11.4729 8.22792 13.5761V19.3237C8.22792 20.5293 9.41641 21.3397 10.4801 20.8597L12.8239 19.8019C13.4124 19.5363 13.7932 18.9328 13.7932 18.2659V13.5759C16.9105 11.4726 19.133 8.67443 20.6919 5.65614C21.66 3.78167 20.2008 1.60514 18.3423 1.40339Z" />
          </svg>
        </div>
      </div>

      <FilterSidePanel
        isOpen={isFilterPanelOpen}
        onClose={() => setIsFilterPanelOpen(false)}
        filters={filters}
        filterConfig={filterConfig}
        handleFilterChange={handleFilterChange}
        clearFilters={clearFilters}
        applyFilters={applyFilters}
      />

      <ProjectDetailSidePanel
        isOpen={isProjectDetailPanelOpen && selectedProject !== null}
        onClose={closeProjectDetailPanel}
        project={selectedProject}
      />

      <div className="work__content">
        {filteredProjects.length === 0 ? (
          <p className="work__content__empty">
            No results found for the selected filter.
          </p>
        ) : (
          filteredProjects.map((result, index) => (
            <div key={index} onClick={() => openProjectDetailPanel(result)}>
              <Project project={result} />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Work;
