// ================ Import Modules ================
// - react dependencies
import React from "react";
import { useFormContext } from "react-hook-form";

// - Styles
import "./index.scss";

const ContactInput = ({ label, type, name, validationRules }) => {
  // ======================= Functions ======================= //
  const { register, isSubmitting, errors } = useFormContext();

  return (
    <div className="contact-input-group">
      <input
        {...register(name, validationRules[name])}
        required
        type={type ? type : "text"}
        className={`contact-input ${errors[name] ? "is-invalid" : ""}`}
        name={name}
        disabled={isSubmitting}
      />
      <span className="highlight"></span>
      <span className="bar"></span>
      <label>{label}</label>
      {errors[name] && (
        <span className="error-message">{errors[name].message}</span>
      )}
    </div>
  );
};

export default ContactInput;
