// ================ Import Modules ================

// Import React and necessary hooks
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import AOS from "aos";
import "aos/dist/aos.css";

// Import component-specific styles
import "./index.scss";

// Define the component
const ContactTextArea = ({
  label,
  name,
  validationRules,
  succes,
  file,
  animation,
}) => {
  // ======================= Functions ======================= //

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  // Access form context
  const { register, isSubmitting, errors } = useFormContext();

  // Render the component
  return (
    <div className="contact-text-area" data-aos={animation}>
      <textarea
        {...register(name, validationRules[name])}
        name={name}
        disabled={isSubmitting}
        className={`contact-textarea ${errors[name] ? "is-invalid" : ""}`}
        rows={5}
        required
      />
      <span className="highlight"></span>
      <span className="bar"></span>
      <label>{label}</label>
      {errors[name] && (
        <span className="error-message">{errors[name].message}</span>
      )}
    </div>
  );
};

// Export the component
export default ContactTextArea;
