import axios from "axios";

// - import react
import { useCallback } from "react";

// === Constants ===
const baseUrl = `${process.env.REACT_APP_API_URL}/forms`;

// === Functions ===

const useForms = () => {
  const submitContactForm = useCallback(async (formData) => {
    // try {
    const response = await axios.post(`${baseUrl}/contact`, formData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
    // } catch (error) {
    //   console.error(
    //     "Error submitting contact form",
    //     error.response.data.message
    //   );
    //   throw error.response.data.message; // Re-throw the error so that it can be handled higher up if necessary
    // }
  }, []);

  return { submitContactForm };
};

export default useForms;
