import { useState, useEffect } from "react";

const useStaticData = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch("/assets/data/data.json")
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error("Error fetching static data:", error));
  }, []);

  return data;
};

export default useStaticData;
