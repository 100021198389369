import React from "react";

import useColor from "../../../hooks/useColor";

import "./index.scss";

// - Scroll to Top Button Component
export const ScrollToTopButton = ({ mainRef }) => {
  const [color] = useColor();
  const scrollToTop = () => {
    if (mainRef.current) {
      mainRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <button
      className={`scroll-to-top`}
      onClick={scrollToTop}
      style={{ background: color }}
    >
      <svg viewBox="0 0 25 25">
        <path d="M18.1588 15.3651L12.1588 9.36511L6.15875 15.3651" />
      </svg>
    </button>
  );
};

export default ScrollToTopButton;
