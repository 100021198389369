import { useState, useEffect } from "react";

const useColor = (defaultColor = "#d1d7d1") => {
  const [color, setColor] = useState(() => {
    // Load the color from local storage if it exists, otherwise use default
    return localStorage.getItem("site-color") || defaultColor;
  });

  useEffect(() => {
    // Save color to local storage whenever it changes
    localStorage.setItem("site-color", color);
    document.documentElement.style.setProperty("--global-picker-color", color);
    document.documentElement.style.setProperty(
      "--global-picker-color-rgba",
      `${color}33`
    );
  }, [color]);

  const changeColor = (newColor) => {
    setColor(newColor);
  };

  return [color, changeColor];
};

export default useColor;
