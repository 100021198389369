import React from "react";
import DOMPurify from "dompurify";
import { marked } from "marked";
import SidePanel from "../sidepanel";

import { FiGithub, FiExternalLink } from "react-icons/fi";

import "./index.scss";

const ProjectDetailSidePanel = ({ isOpen, onClose, project }) => {
  return (
    <SidePanel isOpen={isOpen} onClose={onClose} title={project?.title}>
      <div className="project-detail-panel">
        {project?.video && (
          <video autoPlay loop muted playsInline key={project.id}>
            <source src={project.video} type="video/mp4" />
          </video>
        )}
        {!project?.video && <img src={project?.image} alt={project?.title} />}
        <div className="project-detail-panel__header flex">
          <div className="project-detail-panel__header__title">
            <h3>{project?.title}</h3>
            <p>{project?.date}</p>
          </div>
          <div className="project-detail-panel__project__links">
            {project?.link && (
              <a href={project?.link} target="_blank" rel="noopener noreferrer">
                <FiExternalLink />
              </a>
            )}
            {project?.github &&
              project.github.map((github, index) => (
                <a
                  key={index}
                  href={github}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FiGithub />
                </a>
              ))}
          </div>
        </div>
        <p
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(marked(project?.longdescription || "")),
          }}
        />

        <div className="project-detail-panel__project-tags">
          {project?.technologies.map((tech, index) => (
            <span
              key={index}
              className="project-detail-panel__project-tags__tag"
            >
              {tech}
            </span>
          ))}
        </div>
      </div>
    </SidePanel>
  );
};

export default ProjectDetailSidePanel;
