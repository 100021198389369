import React from "react";
import DOMPurify from "dompurify";
import { marked } from "marked";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { useEffect } from "react";
import { FiExternalLink, FiDownload } from "react-icons/fi";

// - Import Data
import useDocumentTitle from "../../hooks/useDocumentTitle";
import useStaticData from "../../hooks/useStaticData";

import { images } from '../../assets';

import ImageSlider from "../../components/sliders/imageSlider";
import cv from "../../assets/documents/YaniEtienneHildaDegrande.pdf";


import AOS from "aos";
import "aos/dist/aos.css";

import "./index.scss";

const imagesconatiner = ["./assets/images/about/IMG_6560.webp", images.image2, images.image1];

const calculateAge = (birthdate) => {
  const birthDate = new Date(birthdate);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();
  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
};

const RenderText = ({ textData }) => {
  if (!textData || !textData.about || !textData.about.text) {
    return null; // or some fallback content
  }

  const age = calculateAge(textData.birthdate);

  return (
    <p>
      {textData.about.text.map((item, index) => {
        switch (item.type) {
          case "strong":
            return <strong key={index}>{item.content}</strong>;
          case "em":
            return <em key={index}>{item.content}</em>;
          case "highlight":
            return (
              <span className={`highlight ${item.highlightType}`} key={index}>
                {item.content}
              </span>
            );
          case "age":
            return <span key={index}>{age}</span>;
          case "text":
          default:
            return <span key={index}>{item.content}</span>;
        }
      })}
    </p>
  );
};

const RenderSkills = ({ skills }) => {
  if (!skills) {
    return null; // or some fallback content
  }

  return (
    <div className="skills-content">
      <div className="skill-category">
        <h3>Hard Skills</h3>
        {skills.hardSkills.map((category, index) => (
          <ul key={index}>
            <li>
              <strong>{category.category}:</strong>{" "}
              <div className="skill-category__tags">
                {category.skills.map((tech, index) => (
                  <span key={index} className="skill-category__tags__tag">
                    {tech}
                  </span>
                ))}
              </div>
            </li>
          </ul>
        ))}
      </div>
      <div className="skill-category">
        <h3>Soft Skills</h3>
        <ul>
          {skills.softSkills.map((skill, index) => (
            <li key={index} className={`skill-item skill-item-${index % 5}`}>
              <strong>{skill.skill}:</strong> {skill.description}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const About = () => {
  useDocumentTitle("Yani Degrande - About");
  const data = useStaticData();

  const pageData = data?.seo.about;
  const schemaData = pageData?.schema;

  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  if (!data) {
    return (
      <div className="about__loader flex-column">
        <div className="about__loader__header">
          <span className="loading"></span>
          <span className="loading"></span>
        </div>
        <div className="about__loader__info">
          <span className="loading"></span>
          <span className="loading"></span>
          <span className="loading"></span>
          <span className="loading"></span>
        </div>
      </div>
    );
  }
  return (
    <div className="about-container">
      <Helmet>
        <title>{pageData.title}</title>
        <meta name="description" content={pageData.description} />
        <meta name="keywords" content={pageData.keywords} />
        <meta property="og:title" content={pageData.ogTitle} />
        <meta property="og:description" content={pageData.ogDescription} />
        <meta property="og:image" content={pageData.ogImage} />
        <meta property="og:url" content={pageData.ogUrl} />
        <meta name="twitter:title" content={pageData.twitterTitle} />
        <meta
          name="twitter:description"
          content={pageData.twitterDescription}
        />
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
      </Helmet>
      <section className="intro-section">
        <h2>About Me</h2>
        <div className="intro-content">
          <ImageSlider images={imagesconatiner} />
          <RenderText textData={data} />
          <Link to={cv} className="primary-cta flex" target="_blank" download>
            <span>Download my CV  <FiDownload /></span>
            <span>Download my CV  <FiDownload /></span>
          </Link>
        </div>
      </section>

      <section className="skills-section">
        <h2>Skills</h2>
        <RenderSkills skills={data?.skills} />
      </section>

      <section className="languages-section">
        <h2>Languages</h2>
        <ul>
          {data?.languages.map((language, index) => (
            <li key={index} className="flex-column">
              <img src={language.flag} alt={language.language} />
              <div className="languages-section__text">
                <strong>{language.language}</strong>{" "}
                <span>{language.proficiency}</span>
              </div>
            </li>
          ))}
        </ul>
      </section>

      <section className="experience-section">
        <h2>Experience</h2>
        <div className="timeline">
          {data?.experience.map((exp, index) => (
            <div className="timeline-item" key={index}>
              <div className="timeline-dot"></div>
              <div className="timeline-content">
                <h3>{exp.title}</h3>
                <span>
                  {exp.logo && <img src={exp.logo} alt={exp.institution} />}{" "}
                  {exp.institution}, {exp.period}
                  {exp.url && (
                    <a
                      href={exp.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="timeline-content__link"
                    >
                      <FiExternalLink />
                    </a>
                  )}
                </span>
                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(marked(exp.description || "")),
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default About;
