import { Link } from "react-router-dom";

import "./index.scss";

// - Import Data
import useStaticData from "../../hooks/useStaticData";

const Logo = () => {
  const data = useStaticData();

  if (!data) {
    return (
      <div className="logo-loader flex-column">
        <p className="loading"></p>
        <span className="loading"></span>
      </div>
    );
  }
  return (
    <Link to="/" className="logo flex-column">
      <p>{data.logo.heading}</p>
      <span>{data.logo.location}</span>
    </Link>
  );
};

export default Logo;
