import React from "react";
import "./index.scss";

const SidePanel = ({ isOpen, onClose, title, children }) => {
  return (
    <>
      {isOpen && <div className="overlay" onClick={onClose}></div>}

      <div className={`side-panel ${isOpen ? "open" : ""}`}>
        <div className="side-panel-header">
          <button className="side-panel-header__back" onClick={onClose}>
            <svg viewBox="0 0 25 25">
              <path d="M15.3984 18.5783L9.39844 12.5783L15.3984 6.57831" />
            </svg>
            <span>Back to projects</span>
          </button>
        </div>

        <div className="side-panel__content">{children}</div>
      </div>
    </>
  );
};

export default SidePanel;
