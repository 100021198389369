import React, { useState, useRef, useEffect } from "react";
import "./index.scss";

const ImageSlider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [startX, setStartX] = useState(0);
  const [isSwiping, setIsSwiping] = useState(false);
  const [swipeDirection, setSwipeDirection] = useState(null);
  const [showInfo, setShowInfo] = useState(true);
  const sliderRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => setShowInfo(false), 3000); // Show message for 3 seconds
    return () => clearTimeout(timer);
  }, []);

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
    setIsSwiping(true);
  };

  const handleTouchMove = (e) => {
    if (!isSwiping) return;

    const currentX = e.touches[0].clientX;
    const moveX = startX - currentX;
    if (moveX > 50) {
      handleNext();
      setIsSwiping(false);
    } else if (moveX < -50) {
      handlePrevious();
      setIsSwiping(false);
    }
  };

  const handleTouchEnd = () => {
    setIsSwiping(false);
  };

  const handleMouseStart = (e) => {
    setStartX(e.clientX);
    setIsSwiping(true);
  };

  const handleMouseMove = (e) => {
    if (!isSwiping) return;

    const currentX = e.clientX;
    const moveX = startX - currentX;
    if (moveX > 50) {
      handleNext();
      setIsSwiping(false);
    } else if (moveX < -50) {
      handlePrevious();
      setIsSwiping(false);
    }
  };

  const handleMouseEnd = () => {
    setIsSwiping(false);
  };

  const handleNext = () => {
    if (currentIndex < images.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setSwipeDirection("left");
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setSwipeDirection("right");
    }
  };

  return (
    <div className="slider-container">
      {showInfo && (
        <div className="info-message flex-column">
          <svg viewBox="0 0 17 23" className="finger">
            <path d="M1.75981 8.29016C0.771888 4.04264 4.75256 0.318457 8.92493 1.5866C11.7785 2.45384 13.471 5.38513 12.7953 8.29016" />
            <path d="M4.49446 21.5674L3.69648 20.9022C1.84004 19.3548 2.27215 16.3911 4.49287 15.4394L5.30216 15.0931V7.40477C5.30216 6.28804 6.20856 5.38164 7.32529 5.38164C8.44377 5.38164 9.34859 6.28804 9.34859 7.40477V12.7088C13.0016 12.7088 16.1045 14.9442 15.8229 18.3302C15.7463 19.4866 15.3678 20.6028 14.7254 21.5674" />
          </svg>
          <div className="info-message__arrows">
            <svg viewBox="0 0 25 25">
              <path d="M6.17383 12.0156H19.2988" />
              <path d="M6.17383 12.0156L11.7988 17.6406" />
              <path d="M6.17383 12.0156L11.7988 6.39056" />
            </svg>
            <svg viewBox="0 0 25 25">
              <path d="M6.17383 12.0156H19.2988" />
              <path d="M13.6738 17.6406L19.2988 12.0156" />
              <path d="M13.6738 6.39056L19.2988 12.0156" />
            </svg>
          </div>
        </div>
      )}
      <div
        className={`slider-wrapper ${showInfo ? "blurred" : ""}`}
        ref={sliderRef}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onMouseDown={handleMouseStart}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseEnd}
        onMouseLeave={handleMouseEnd}
      >
        {images.map((image, index) => (
          <div
            className={`slide ${swipeDirection}`} // Apply direction as class
            key={index}
            style={{
              zIndex:
                currentIndex === index ? 10 : index < currentIndex ? 5 : 0,
              transform:
                currentIndex === index
                  ? "rotate(0)"
                  : index === currentIndex - 1
                  ? "rotate(-10deg)"
                  : index === currentIndex + 1
                  ? "rotate(10deg)"
                  : "rotate(0)",
              opacity: currentIndex === index ? 1 : 0,
              transition: "transform 0.5s, opacity 0.5s, z-index 0.5s", // Smooth transition
            }}
          >
            <img src={image} alt={`Slide ${index}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;
