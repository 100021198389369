import React from "react";
import "./index.scss";

const Project = ({ project }) => {
  return (
    <div className="project">
      <div className="project__image">
        {project.video && (
          <video autoPlay loop muted playsInline key={project.id}>
            <source src={project.video} type="video/mp4" />
          </video>
        )}
        {!project.video && <img src={project.image} alt={project.title} />}
      </div>
      <div className="project__details">
        <h3 className="project__details__title">{project.title}</h3>
        <p className="project__details__description">{project.description}</p>
        <div className="project__details__tags">
          {project.technologies.map((tech, index) => (
            <span key={index} className="project__tag">
              {tech}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Project;
