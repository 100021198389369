// - Import dependencies
import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";

// - Import Data
import useStaticData from "../../../hooks/useStaticData";
import useColor from "../../../hooks/useColor";

// - Import Components
import Logo from "../../logo";

// - Import Styles
import "./index.scss";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const data = useStaticData();
  const [color, changeColor] = useColor("#d1d7d1");

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  // Close the navbar when clicking on a link
  const handleLinkClick = () => {
    setIsOpen(false);
  };

  if (!data) {
    return (
      <nav role="navigation" className={`navigation-menu flex-between`}>
        <Logo />
        <div className="navbar-loader flex">
          <span className="loading"></span>
          <span className="loading"></span>
          <span className="loading"></span>
          <span className="loading"></span>
        </div>
      </nav>
    );
  }

  return (
    <nav
      role="navigation"
      className={`navigation-menu flex-between`}
      ref={menuRef}
    >
      <Logo />
      <div
        className={`navigation-menu__toggle flex-between ${isOpen}`}
        onClick={toggleMenu}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul className={`navigation-menu__list flex-column ${isOpen}`}>
        {data.navigation.map((item, index) =>
          index === data.navigation.length - 1 ? (
            <li key={index}>
              <Link to={item.url} onClick={handleLinkClick}>
                <span>{item.label}</span>
                <span>{item.label}</span>
              </Link>
            </li>
          ) : (
            <li key={index}>
              <Link to={item.url} onClick={handleLinkClick}>
                {item.label}
              </Link>
            </li>
          )
        )}
        <div className="color-picker">
          <input
            type="color"
            id="color-picker"
            name="color-picker"
            value={color}
            onChange={(e) => changeColor(e.target.value)}
          />
        </div>
      </ul>
    </nav>
  );
};

export default Navbar;
