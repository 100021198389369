import React, { useEffect, useRef } from "react";
import "./index.scss"; // Import the CSS file

const CustomToast = ({ message, visible, onClose }) => {
  const toastRef = useRef(null);

  useEffect(() => {
    if (visible) {
      const timer = setTimeout(onClose, 3000); // Auto close after 3 seconds
      return () => clearTimeout(timer);
    }
  }, [visible, onClose]);

  const handleClose = () => {
    setTimeout(onClose, 600); // Wait for animation to finish before calling onClose
  };

  return (
    <div className={`toast ${visible ? "show" : ""}`} ref={toastRef}>
      <span>{message}</span>
      <button className="close-button" onClick={handleClose}>
        &#10005;
      </button>
    </div>
  );
};

export default CustomToast;
