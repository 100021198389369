import React from "react";
import SidePanel from "../sidepanel";

import "./index.scss";

const FilterSidePanel = ({
  isOpen,
  onClose,
  filters,
  filterConfig,
  handleFilterChange,
  clearFilters,
  applyFilters,
}) => {
  return (
    <SidePanel isOpen={isOpen} onClose={onClose} title="Filter Projects">
      <div className="filter-panel flex">
        <div className="filter-panel__header flex-between">
          <h3>Filter Projects</h3>
          <button className="clear-filters" onClick={clearFilters}>
            <svg viewBox="0 0 23 23">
              <path d="M6.51367 13.11L11.2745 1.38866" />
              <path d="M17.9336 21.7508H21.5622" />
              <path d="M15.9082 17.8208H19.5367" />
              <path d="M13.5898 13.9062H17.2185" />
              <path d="M10.4764 16.7006C10.4764 14.4842 8.25007 13.3049 6.76575 13.147C2.56071 12.6996 1.16077 16.3772 1.23733 19.4653C1.27161 20.8482 2.53541 21.7507 3.91876 21.7507H14.3357C13.5935 21.7507 10.4764 20.2541 10.4764 16.7006Z" />
            </svg>
            <span>Clear Filters</span>
          </button>
        </div>
        <div className="filter-panel__content">
          {Object.entries(filterConfig).map(([filterName, config]) => (
            <div key={filterName} className="filter-group">
              {filterName !== "category" && (
                <h4>
                  {filterName.charAt(0).toUpperCase() + filterName.slice(1)}
                </h4>
              )}

              {config.type === "checkbox" && (
                <div className="chip-container">
                  {config.options.map((option) => (
                    <label
                      key={option}
                      className={`chip ${
                        filters[filterName].includes(option) ? "selected" : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        checked={filters[filterName].includes(option)}
                        onChange={(e) =>
                          handleFilterChange(
                            filterName,
                            e.target.checked
                              ? [...filters[filterName], option]
                              : filters[filterName].filter(
                                  (item) => item !== option
                                )
                          )
                        }
                      />
                      {option}
                    </label>
                  ))}
                </div>
              )}
              <div className="radio-container">
                {config.type === "radio" &&
                  config.options.map((option) => (
                    <label
                      key={option}
                      className={`radio-label ${
                        filters[filterName] === option ? "selected" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        name={filterName}
                        checked={filters[filterName] === option}
                        onChange={() => handleFilterChange(filterName, option)}
                      />
                      <span>{option}</span>
                    </label>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </SidePanel>
  );
};

export default FilterSidePanel;
