import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import useForms from "../../api/_form";
import DOMPurify from "dompurify";
import { Helmet } from "react-helmet-async";
import AOS from "aos";
import "aos/dist/aos.css";
import "./index.scss"; // Importing the updated styles
import ContactInput from "../../components/inputs/contactInput";
import ContactTextArea from "../../components/inputs/contactTextArea";
import useStaticData from "../../hooks/useStaticData";
import { FiTrash2 } from "react-icons/fi";

import CustomToast from "../../components/toast";

const validationRules = {
  firstname: {
    required: "First name is required",
    minLength: {
      value: 2,
      message: "First name must be at least 2 characters",
    },
    maxLength: {
      value: 50,
      message: "First name must be less than 50 characters",
    },
  },
  lastname: {
    required: "Last name is required",
    minLength: {
      value: 2,
      message: "Last name must be at least 2 characters",
    },
    maxLength: {
      value: 50,
      message: "Last name must be less than 50 characters",
    },
  },
  email: {
    required: "Email is required",
    maxLength: {
      value: 128,
      message: "Email can't be longer than 128 characters",
    },
    pattern: {
      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      message: "Invalid email format",
    },
  },
  phone: {
    required: "Phone number is required",
  },
  country: {
    required: "Country is required",
    minLength: {
      value: 2,
      message: "Country must be at least 2 characters",
    },
    maxLength: {
      value: 50,
      message: "Country must be less than 50 characters",
    },
  },
  message: {
    required: "Message is required",
    minLength: {
      value: 10,
      message: "Message must be at least 10 characters",
    },
    maxLength: {
      value: 1000,
      message: "Message must be less than 1000 characters",
    },
  },
  services: {
    validate: (value) =>
      value.length > 0 || "At least one service must be selected",
  },
};

const ContactForm = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState(null);
  const [showAllServices, setShowAllServices] = useState(false);

  const handleToggle = () => setShowAllServices((prev) => !prev);


  const closeToast = () => {
    setSuccess(false);
    setMessage(null);
  };

  const services = [
    {
      name: "Website Design",
      description:
        "I build beautiful websites that look great on phones, tablets, and computers! Your brand, your style, everywhere.",
    },
    {
      name: "UX/UI Design",
      description:
        "I create easy-to-use layouts that make websites fun and simple. No more confusing menus or buttons!",
    },
    {
      name: "SEO Optimization",
      description:
        "I help your website show up higher on Google, so more people can find you quickly!",
    },
    {
      name: "Custom Web Apps",
      description:
        "I build web apps that solve problems and make life easier, from business tools to creative projects.",
    },
    {
      name: "E-Commerce Solutions",
      description:
        "Need an online store? I design secure, easy-to-use shops where your customers can buy products quickly and safely.",
    },
    {
      name: "Mobile App Development",
      description:
        "I create apps that work on your phone or tablet, so your business or idea is always at your fingertips!",
    },
    {
      name: "Maintenance & Support",
      description:
        "I keep your website and apps running smoothly, fixing any issues and keeping everything up-to-date.",
    },
    {
      name: "Chat with Me",
      description:
        "Let's talk! I'll listen to your ideas and find the best way to make them real with technology.",
    },
  ];

  useEffect(() => {
    AOS.init({ duration: 1000 });

    if (success) {
      const timer = setTimeout(() => setSuccess(false), 5000);
      return () => clearTimeout(timer);
    }

    if (error) {
      const timer = setTimeout(() => setError(null), 5000); // Clear error after 5 seconds
      return () => clearTimeout(timer);
    }
  }, [success, error]);

  const formsApi = useForms();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    watch,
  } = useForm();

  const onSubmit = async (formData) => {
    setLoading(true);
    setError(null);

    const sanitizedData = {
      firstname: DOMPurify.sanitize(formData.firstname),
      lastname: DOMPurify.sanitize(formData.lastname),
      email: DOMPurify.sanitize(formData.email),
      phone: DOMPurify.sanitize(formData.phone),
      country: DOMPurify.sanitize(formData.country),
      message: DOMPurify.sanitize(formData.message),
      services: formData.services || [],
    };

    try {
      await formsApi.submitContactForm(sanitizedData);
      setSuccess(true);
      reset();

      setMessage("A confirmation email has been sent to your email address.");
    } catch (error) {
      setError(error.response?.data.message);
    } finally {
      setLoading(false);
    }
  };

  const selectedServices = watch("services") || [];

  const data = useStaticData();

  if (!data) {
    return "loading...";
  }

  const pageData = data?.seo.contact;
  const schemaData = pageData?.schema;

  return (
    <div className="contact-form-container">
      <Helmet>
        <title>{pageData.title}</title>
        <meta name="description" content={pageData.description} />
        <meta name="keywords" content={pageData.keywords} />
        <meta property="og:title" content={pageData.ogTitle} />
        <meta property="og:description" content={pageData.ogDescription} />
        <meta property="og:image" content={pageData.ogImage} />
        <meta property="og:url" content={pageData.ogUrl} />
        <meta name="twitter:title" content={pageData.twitterTitle} />
        <meta
          name="twitter:description"
          content={pageData.twitterDescription}
        />
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
      </Helmet>
      <div className="contact-form-section">
        <CustomToast message={message} visible={success} onClose={closeToast} />
        <h2>Let's have a Conversation</h2>
        <p className="description">
          Whether you’re planning a new project or just want to explore your
          options, let’s talk! Fill out the form below, and we’ll be in touch
          shortly.
        </p>

        <FormProvider {...{ register, handleSubmit, errors }}>
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <ContactInput
                label={"Your First Name"}
                name="firstname"
                validationRules={validationRules}
              />
              <ContactInput
                label={"Your Last Name"}
                name="lastname"
                validationRules={validationRules}
              />
            </div>

            <ContactInput
              label={"Email Address"}
              type={"Email"}
              name="email"
              validationRules={validationRules}
            />
            <div className="form-group">
              <ContactInput
                label={"Country"}
                name="country"
                validationRules={validationRules}
              />
              <ContactInput
                label={"Phone Number"}
                name="phone"
                validationRules={validationRules}
              />
            </div>

            <ContactTextArea
              label={"Tell Me About Your Project"}
              name="message"
              validationRules={validationRules}
              success={success}
              file="file"
            />

            {/* Services Selection */}
            <div className="services">
              <span className="services__header">
                Select the Services You Need
              </span>
              <div
                className={`service-options ${showAllServices ? "expanded" : ""
                  }`}
              >
                {services
                  .slice(0, showAllServices ? services.length : 2)
                  .map((service) => {
                    const isChecked = selectedServices.some(
                      (selected) => selected.name === service.name
                    );

                    return (
                      <div
                        className={`service ${isChecked ? "checked" : ""}`}
                        key={service.name}
                      >
                        <label className="service-label">
                          <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setValue("services", [
                                  ...selectedServices,
                                  service,
                                ]);
                              } else {
                                setValue(
                                  "services",
                                  selectedServices.filter(
                                    (selected) => selected.name !== service.name
                                  )
                                );
                              }
                            }}
                          />
                          <span className="service__name">{service.name}</span>
                          <span className="service__description">
                            {service.description}
                          </span>
                        </label>
                      </div>
                    );
                  })}
                <div className="buttons flex">
                  <button
                    type="button"
                    className="show-more-button"
                    onClick={handleToggle}
                  >
                    {showAllServices ? (
                      <svg viewBox="0 0 24 25">
                        <path d="M18 15.5L12 9.5L6 15.5" />
                      </svg>
                    ) : (
                      <svg viewBox="0 0 24 25">
                        <path d="M6 9.5L12 15.5L18 9.5" />
                      </svg>
                    )}
                  </button>
                  <button type="submit">
                    {loading
                      ? "Submitting..."
                      : error
                        ? "Error"
                        : success
                          ? "Success"
                          : "Submit"}
                  </button>
                  <button type="reset">
                    <FiTrash2 />
                  </button>
                </div>
              </div>
              {errors.services && (
                <span className="error">{errors.services.message}</span>
              )}
              {error && <span className="error">{error}</span>}
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default ContactForm;
